import HeadingImage from '../assets/p 3.png'
import PeaceImage from '../assets/p 4.png'
import MagicImage from '../assets/p 5.png'
import MoodImage from '../assets/p 6.png'
import ConnectImage from '../assets/p 7.png'
import WaitImage from '../assets/p 8.png'

const cardData = [
  {
    id: 1,
    title: 'Peace of mind',
    description:
      "No need to worry any longer when you can hear your baby's heartbeat at home. Now you can have peace of mind during the different stages of your pregnancy. A must-have for every mom-to-be",
    img: PeaceImage,
  },
  {
    id: 2,
    title: 'Share The Magic',
    description:
      "Specially designed for expecting parents, this Fetal Doppler lets you share the experience of listening to your baby's heartbeat with the whole family and enjoy bonding time together.",
    img: MagicImage,
  },
  {
    id: 3,
    title: 'Mood Improvement',
    description:
      "Once you hear your baby's heartbeat, you'll never forget it. The peace and stability that comes from hearing your unborn baby's heartbeat can improve your overall mood and create a calming effect!",
    img: MoodImage,
  },
  {
    id: 4,
    title: 'Connecting Baby & Partner',
    description:
      'Your partnership is about to take on a whole new meaning. Your partner can bond with your your baby from the very beginning.',
    img: ConnectImage,
  },
  {
    id: 5,
    title: 'No Waiting Between Ultrasounds',
    description:
      "Discover your baby's heartbeat live on the spot, anytime and anywhere!",
    img: WaitImage,
  },
]
const BenefitsSection = () => {
  return (
    <section className="w-full max-w-screen-xl mx-auto">
      <div className="flex justify-center md:pl-12 py-8">
        <img
          src={HeadingImage}
          alt="mainBenefits"
          className="object-contain h-32"
        />
      </div>
      <div className="flex justify-center  flex-wrap w-full md:py-8 pb-8  gap-8 px-8 md:px-0">
        {cardData.map(data => (
          <div key={data.id} className="md:w-[24%]">
            <img src={data.img} alt="img" className="h-24 mx-auto my-8" />
            <p className="font-bold font-openSans">
              {data.title} -{' '}
              <span className="font-normal hyphens">{data.description}</span>{' '}
            </p>
          </div>
        ))}
      </div>
    </section>
  )
}
export default BenefitsSection
