const ConductivityGelSection = () => {
  // we didnt have section container before wit k4
  return (
    <section className="relative w-full mx-auto pb-12 overflow-hidden">
      <div className="conductivitySectionImg">
        {/* <div className="absolute w-[30%] top-36 left-8">
        <h4 className="uppercase font-openSans font-semibold text-lg">
          Conductivity Gel - <br />A Great Addition <br /> To Your Doppler
        </h4>
        <p>
          Ultrasound gel is a conductive medium that creates a bond between the
          skin and the ultrasound transducer. The dense, sticky nature of
          ultrasound gel helps it adhere to any surface with great stability and
          facilitates secure transfer of sound waves from the probe through the
          gel and onto your skin, while preventing other unwanted vibrations.
          It's designed to reduce overall static and optimize the Doppler's pick
          up capabilities.
        </p>
      </div> */}
      </div>
    </section>
  )
}
export default ConductivityGelSection
