import deviceInfo from '../assets/k 1.jpg'
const InfoSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto w-full md:space-y-6 py-4">
      <h2 className="font-semibold text-2xl text-center font-openSans mb-4 md:mb-0">
        WHAT IS A FETAL DOPPLER?
      </h2>
      <p className="font-openSans text-center px-12 md:px-0 md:w-[48%] mx-auto">
        This Fetal Doppler is a convenient, safe and easy-to-use way to hear
        your baby's heartbeat anytime and anywhere. It uses the same technology
        as that used at prenatal checkups to provide you with peace of mind and
        reassurance.
      </p>
      <img
        className="object-contain h-[30rem] mx-auto py-4"
        src={deviceInfo}
        alt="device-with-info"
      />
    </section>
  )
}
export default InfoSection
