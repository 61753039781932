import image from '../assets/p 12.png'
import {
    Accordion,
    AccordionItem,
    AccordionItemButton,
    AccordionItemHeading,
    AccordionItemPanel,
} from 'react-accessible-accordion'

const faqData = [
    {
        id: 1,
        title: 'What is a Fetal Doppler?',
        answer: 'The Fetal Doppler is a hand-held,pocket-sized device used to detect and listen to the Baby’s heartbeat. The fetal probe uses the Doppler Effect (high frequency sound waves) to provide an audible simulation to amplify the sound of Baby’s heartbeat. It is similar to a stethoscope, but multiple people can hear the output. ',
    },
    {
        id: 2,
        title: 'Is it safe to use at home?',
        answer: 'Hand-held Dopplers are thought to be perfectly safe, if used according to the guidelines.As long as you don’t use a fetal doppler too often, the device should be perfectly safe. Limit your use to once every other day for about 5 minutes.When using your fetal Doppler it is suggested that you use it in short intervals, infrequently, and following the first trimester. As a general rule, an exam with a fetal doppler should never last longer than 10 minutes. Past results have indicated no adverse side effects. It is commonly recommended that like everything in life, moderation is the best policy.',
    },
    {
        id: 3,
        title: 'How does it work?',
        answer: 'First you lubricate your abdomen with ultrasound gel, then place the probe on your abdomen. The sound waves emitted from the probe intersect movement from the baby\'s heartbeat and the device translates the movement into waves projected back to an amplifier. Sometimes it can be hard to distinguish baby’s heartbeat from other sounds the fetal Doppler picks up, such as blood flow or air movement.',
    },
    {
        id: 4,
        title: 'Ultrasound gel: What is it, and why do we need it?',
        answer: 'Ultrasound gel is a conductive medium that creates a bond between the skin and the ultrasound transducer. The gel is composed of water and propylene glycol and is formulated to reduce overall static.  Many people notice that the gel tends to be thick and sticky, however, it needs to have a sticky texture so that it does not drip off of your skin.The ultrasound gel is needed in order to create a barrier between the fetal Doppler probe and the expected mother’s stomach. The Doppler works by using high frequency sound waves into the body (Doppler waves) to detect audio sound, i.e. fetal heartbeat. We also recommend using Aloe Vera gel if you cannot purchase conductivity gel and this will work just as well.',
    },
    {
        id: 5,
        title: "What if I can't find the baby's heartbeat?",
        answer: 'You might not be able to hear a baby’s heartbeat the first time you attempt. Most commonly, this is because it’s too early in the pregnancy.  If you don\'t find your baby\'s heartbeat in this period of time, take a break. If you are concerned contact your health care provider. If not, try again in a few days. Finding a heartbeat can be difficult at first, practice patience while operating. Refer to our Tips for Use sheet for best times and positions when trying to locate your baby\'s heartbeat. ',
    },
    {
        id: 6,
        title: 'When can I start using the Fetal Doppler?',
        answer: 'As a general rule, from week 13 of the pregnancy on. An important note is to remember that everyone is different, so not finding your baby\'s heartbeat early isn\'t a reason for concern. On average, most women are able to hear the fetal heartbeat from the 14th week on. ',
    },
    {
        id: 7,
        title: 'What is the average Fetal heart beat?',
        answer: 'A fetal heart rate is usually between 110 and 160 beats per minute, which is much faster than an adult’s.It can vary by 5 to 25 beats per minute. The fetal heart rate may change as your baby responds to conditions in your uterus.The baby\'s normal fetal heart rate will also vary normally, just as your heart rate does. Movement, sleeping and other activities can cause a normal variation also outside of the typical range momentarily. In addition, extra noises, such as the mother\'s heartbeat or stomach rumbling or static caused by Doppler probe movement, can mask the signals causing unreliable Doppler measurement.',
    },
    {
        id: 8,
        title:
            'I am hearing a beeping sound when I use the Doppler, what does that mean?',
        answer: 'The normal heart rate of the fetus is between 120-160. When it is lower than 120 or higher than 160, the fetal heart rate instrument will emit a audible sound. The doppler is working fine. (refer to the 2 videos below - the center road at 93, it will emit a audible sound).',
    },
    {
        id: 10,
        title: 'What are the benefits of using the prenatal fetal monitor?',
        answer: 'Fetal Doppler\'s can help reduce anxiety during pregnancy and offer reassurance especially during the early stages before it is even possible to feel the baby moving. Listening to the fetal heartbeat is also a great way for a parent-to-be to bond with their unborn baby',
    },
]
const FAQSection = () => {
    return (
        <section className="bg-[#CEB3BC] h-[140vh] sm:h-[155vh] lg:h-[130vh] xl:h-[120vh] relative">
            <img src={image} className="w-full absolute h-32 top-0" alt="design"/>
            <img
                src={image}
                className="w-full absolute bottom-0 rotate-180 h-32"
                alt="design"
            />
            <div
                className="absolute w-full text-center md:text-left md:w-fit top-[10%] lg:top-[12%] left-[50%] -translate-x-[50%] mx-auto flex flex-col justify-center items-center overflow-hidden">
                <h3 className="text-white font-openSans text-3xl md:text-5xl uppercase mb-8">
                    Frequently Asked Questions
                </h3>
                <Accordion>
                    {faqData.map(data => (
                        <div key={data.id} className="">
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>{data.title}</AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p className="text-white drop-shadow-lg">{data.answer}</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </div>
                    ))}
                </Accordion>
            </div>
        </section>
    )
}
export default FAQSection
