import {useState} from 'react'
import Countdown from "react-countdown";
import blueDevice from '../assets/dopplerBlue.jpg'
import pinkDevice from '../assets/dopplerPink.jpg'
import blueDeviceBundle from '../assets/dopplerBlueBundle.webp'
import pinkDeviceBundle from '../assets/dopplerPinkBundle.webp'
import Stars from '../assets/p 11.png'
import warrantyImage from '../assets/1yr warranty.png'
import truckImage from '../assets/truck-fast-solid.svg'
import packageImage from '../assets/boxes-packing-solid.svg'
import shieldImage from '../assets/shield-halved-solid.svg'
import dollarImage from '../assets/dollar-sign-solid.svg'

const options = [
    {
        name: 'Royal Blue',
        price: "49.95",
        priceInstead: "99.95",
        image: blueDevice,
        link: "https://checkout.cradlemebaby.shop/dl/hfa-u9w-7rn"
    },
    {
        name: 'Rose Pink',
        price: "49.95",
        priceInstead: "99.95",
        image: pinkDevice,
        link: "https://checkout.cradlemebaby.shop/dl/she-0vc-ha8"
    },
    {
        name: 'Royal Blue Bundle', price: "77.95",
        priceInstead: "124.90", image: blueDeviceBundle,
        link: "https://checkout.cradlemebaby.shop/dl/f3g-6bj-uxt"
    },
    {
        name: 'Rose Pink Bundle', price: "77.95",
        priceInstead: "124.90", image: pinkDeviceBundle,
        link: "https://checkout.cradlemebaby.shop/dl/raz-378-l0i"
    },
]

const featuresData = [
    {id: 0, img: truckImage, name: 'FREE SHIPPING'},
    {id: 1, img: packageImage, name: 'EASY RETURNS'},
    {id: 2, img: shieldImage, name: 'SECURE CHECKOUT'},
    {id: 3, img: dollarImage, name: 'NO RECURRING CHARGES'},
]

const tableData = [
    {
        id: 0,
        name: 'Peace of Mind',
        desc: "Relieve your worrying and anxiety by hearing your baby's heartbeat at home.",
    },
    {
        id: 1,
        name: 'Share The Magic',
        desc: "Share the experience of listening to your baby's heartbeat with the entire family.",
    },
    {
        id: 2,
        name: 'Mood Improvement',
        desc: "Hearing your baby's heartbeat can help improve your overall mood and create a calming effect.",
    },
    {
        id: 3,
        name: 'Convenience',
        desc: 'No waiting between ultrasounds. Now you can listen at home or on the go whenever you want to.',
    },
    {
        id: 4,
        name: 'Connecting Baby & Partner',
        desc: 'Now your partner can bond with your baby from the very beginning.',
    },
]

const renderer = ({hours, minutes, seconds}) => {
    return <p className="text-emerald-500">
        00 <span className="text-black mx-2 relative -top-1">:</span> {hours}{' '}
        <span className="text-black mx-2 relative -top-1">:</span> {minutes}{' '}
        <span className="text-black mx-2 relative -top-1">:</span> {seconds}
    </p>
};


const HeroSection = () => {
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const [bundle, setBundle] = useState(false);
    const [timerSeconds] = useState(Date.now() + 1000 * 60 * 60);

    const selectedOption = options[selectedOptionIndex + offset];

    const onHandleSetBundle = value => {
        setBundle(value);
        setOffset(value ? 2 : 0);
    }

    return (
        <section
            className="grid grid-cols-1 md:grid-cols-2 my-10 py-4 max-w-screen-xl mx-auto place-items-center md:items-start gap-y-8 md:gap-y-0 md:mb-32">
            {/* LEFT */}
            <img
                src={selectedOption.image}
                alt="device"
                className="object-contain h-[25rem] sm:h-[30rem] lg:h-[40rem]"
            />
            {/* RIGHT */}

            <div className="w-full text-center md:text-left space-y-1">
                <p className="text-gray-400 tracking-wider text-xs uppercase">
                    CRADLEMEBABY
                </p>
                <h2 className="text-3xl font-medium leading-normal">
                    Prenatal Fetal <br/> Doppler
                </h2>

                <div className="flex gap-x-4 items-center py-2 justify-center md:justify-start">
                    <p className="text-black font-bold">${selectedOption.price}</p>
                    <p className="line-through decoration-1	text-sm">${selectedOption.priceInstead}</p>
                    <div className="uppercase bg-black text-white w-fit text-center px-4">
                        SALE
                    </div>
                </div>

                <img src={Stars} className="h-4 mx-auto md:mx-0" alt="5star"/>

                <div className="py-2 space-y-2">
                    <h6 className="font-semibold text-xl">Choose Color</h6>
                    <div className="space-x-6">
                        <button
                            onClick={() => setSelectedOptionIndex(0)}
                            className="bg-[#4169e1] text-white py-2 px-6 rounded-full"
                        >
                            Royal Blue
                        </button>
                        <button
                            onClick={() => setSelectedOptionIndex(1)}
                            className="bg-[#fd4a82] text-white py-2 px-6 rounded-full"
                        >
                            Rose Pink
                        </button>
                    </div>
                </div>

                {/* before w-fit changed to some values */}
                <div className="py-2 w-[24rem] mx-auto md:mx-0">
                    <h6 className="font-semibold text-xl">Bundle My Order</h6>
                    <p className="text-xs font-medium">
                        Doppler, 3pk Conductivity Gel & 12 Month Warranty
                    </p>
                    <div className="space-y-2 mt-2">
                        <button
                            className={`bundleButtons ${bundle && 'bg-yellow-100/70'}`}
                            onClick={() => onHandleSetBundle(true)}
                        >
                            Yes
                        </button>
                        <button
                            className={`bundleButtons ${!bundle && 'bg-yellow-100/70'}`}
                            onClick={() => onHandleSetBundle(false)}
                        >
                            No
                        </button>
                    </div>
                </div>

                <div className="space-y-2 pb-4">
                    <p className="font-medium">Offer ends soon. Hurry up!</p>
                    <div>
                        <Countdown
                            date={timerSeconds}
                            renderer={renderer}
                            zeroPadTime={2}
                        />
                    </div>
                </div>

                {/* w-[19.125rem] */}
                <a href={selectedOption.link}>
                    <button className=" text-white font-semibold bg-emerald-400 py-2 w-[24rem]">
                        BUY NOW
                    </button>
                </a>

                <div className="flex w-[24rem] py-8 px-4 mx-auto md:mx-0">
                    {featuresData.map(item => (
                        <div
                            key={item.id}
                            className="flex flex-col items-center  flex-1 wordSpacingUnlimited"
                        >
                            <img src={item.img} alt="img" className="h-9 text-gray-600"/>
                            <p className="text-[10px] font-semibold text-gray-600 text-center mt-1 uppercase ">
                                {item.name}
                            </p>
                        </div>
                    ))}
                </div>

                <div className="flex items-center mx-auto w-fit md:mx-0  md:-ml-11 pb-4">
                    <img alt="warranty" src={warrantyImage} className="h-16 mr-2"/>
                    <p className="">
                        <span className="uppercase font-semibold ">FREE</span> 1 Year
                        Warranty with Every{' '}
                        <span className="font-semibold ">Bundle Order</span>
                    </p>
                </div>

                {/* Table */}
                <div className="border-2 border-gray-500 max-w-[30rem] md:-ml-11 mx-auto md:mx-0">
                    <h6 className="font-semibold text-center py-2 border-b-2 border-gray-500">
                        Benefits of this product
                    </h6>
                    {tableData.map(data => (
                        <p key={data.id} className="px-2 py-1 font-medium text-sm">
                            <span className="text-green-500 font-extrabold text-xl">🗸</span>{' '}
                            <span className="font-semibold">{data.name} - </span>
                            {data.desc}
                        </p>
                    ))}
                </div>
            </div>
        </section>
    )
}
export default HeroSection
