import Img from '../assets/k 2.jpg'
const EligibilitySection = () => {
  return (
    <section className="bg-[#FF7BAA] py-8 md:space-y-8">
      <h3 className="font-medium text-2xl text-center font-openSans mb-4 md:mb-0 text-white">
        HOW SOON CAN I USE DOPPLER
      </h3>
      <p className="text-white font-openSans  px-12 md:px-0 md:w-[50%] mx-auto hyphens">
        As a general rule, from week 13 of the pregnancy on. An important note
        is to remember that everyone is different, so not finding your baby's
        heartbeat early isn't a reason for concern. On average, most women are
        able to hear the fetal heartbeat from the 14th weeks on.
      </p>
      <div className="py-8 md:py-4 overflow-hidden relative w-fit mx-auto">
        <img
          src={Img}
          alt="eligiblity"
          className="md:h-[40rem]  object-contain  overflow-hidden relative -left-1"
        />
      </div>
    </section>
  )
}
export default EligibilitySection
