import vid1 from '../assets/vid1.mp4';
import vid2 from '../assets/vid2.mp4';
import vid3 from '../assets/vid3.mp4';


const VideoSection = () => {
    return (
        <div className="flex max-w-screen-xl mx-auto gap-16 py-12 flex-col items-center md:flex-row">
            {[vid1, vid2, vid3]
                .map((v, i) => (
                    <video
                        key={i}
                        className="w-[50%] md:w-[25%] mx-auto"
                        src={v}
                        controls
                    />
                ))}
        </div>
    )
}
export default VideoSection
