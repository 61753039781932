import BenefitsSection from './components/BenefitsSection'
import ConductivityGelSection from './components/ConductivityGelSection'
import DiscountBanner from './components/DiscountBanner'
import EligibilitySection from './components/EligibilitySection'
import FAQSection from './components/FAQSection'
import HeroSection from './components/HeroSection'
import HowToUseSection from './components/HowToUseSection'
import InfoSection from './components/InfoSection'
import JoinSection from './components/JoinSection'
import ReviewsSection from './components/ReviewsSection'
import ShopSection from './components/ShopSection'
import VideoSection from './components/VideoSection'

function App() {
  return (
    <div className="">
      {/*   // md:max-w-[73.12500rem] */}
      <DiscountBanner />
      <HeroSection />
      <InfoSection />
      <EligibilitySection />
      <HowToUseSection />
      <ConductivityGelSection />
      <BenefitsSection />

      <VideoSection />
      <ReviewsSection />
      <FAQSection />

      <ShopSection />

      <JoinSection />
    </div>
  )
}

export default App
