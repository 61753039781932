import userImg from '../assets/p 10.png'
import starsImg from '../assets/p 11.png'

//Can add this to replace overflowing text with dots text-overflow: ellipsis;
// overflow: hidden;
// white-space: nowrap;
const reviewData = [
  {
    id: 1,
    name: 'Adrianna H.',
    img: '',
    ratings: '',
    comment:
      'I love this product! It really gives me peace of mind after my miscarriage and helps me bond with my baby!',
  },
  {
    id: 2,
    name: 'Becky M.',
    img: '',
    ratings: '',
    comment: "I'm insanely happy with such a product!!! thank you very much",
  },
  {
    id: 3,
    name: 'Daniel R.',
    img: '',
    ratings: '',
    comment:
      'Great for us especially because, we can use it at the comfort of our home while having a home birth.',
  },
  {
    id: 4,
    name: 'Louise P.',
    img: '',
    ratings: '',
    comment:
      "Absolutely love it! Took a while to find my monkey but it's brilliant to put ur mind at rest as she's not moved much 2day!So easy to use would recommend for any time mum.",
  },
  {
    id: 5,
    name: 'Bianca G.',
    img: '',
    ratings: '',
    comment:
      "This thing is a life saver... being a first time mom it brings such ease when you want to hear your little ones heartbeat whenever you want too! Don't second guess the purchase!",
  },
  {
    id: 6,
    name: 'Migdalia M.',
    img: '',
    ratings: '',
    comment:
      "It was a gift for my daughter and she absolutely loved it. Works very well. We immediately tried it out and heard the baby's heartbeat.",
  },
  {
    id: 7,
    name: 'Amanda W.',
    img: '',
    ratings: '',
    comment:
      'I love it! This is my rainbow so it gives me a peace of mind to hear that my baby girl is well.',
  },
  {
    id: 8,
    name: 'Rachel K.',
    img: '',
    ratings: '',
    comment: 'Fast delivery and loved the product!',
  },
  {
    id: 9,
    name: 'Nancy G.',
    img: '',
    ratings: '',
    comment:
      "Peace of mind and hearing your baby's heartbeat is truly priceless! Love this doppler!",
  },
]
const ReviewsSection = () => {
  return (
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-flow-row gap-10 px-8 mx-auto max-w-screen-xl py-10">
      {reviewData.map(data => (
        <div key={data.id} className="space-y-3">
          <div className="flex gap-4">
            <div>
              <p className="text-[#CEB3BC] font-openSans text-lg font-medium">
                {data.name}
              </p>
              <img alt="5stars" src={starsImg} className="h-4" />
            </div>
          </div>
          <p className="text-sm font-openSans hyphens w-[90%]">
            {data.comment}
          </p>
        </div>
      ))}
    </section>
  )
}
export default ReviewsSection
