import loveHandImg from '../assets/p 18.png'
// import ovalCurveImg from '../assets/p 17.png'
import img2 from '../assets/k 6.jpg'
import img1 from '../assets/k 7.jpg'
import img3 from '../assets/k 8.jpg'
import deviceImg from '../assets/k 9.jpg'
const JoinSection = () => {
  // bg-[#CEB3BC]
  return (
    <section className="relative">
      <div className="bg-[#ceb3bc] ovalCurveImg">
        <div className="gap-8 hidden md:flex  absolute left-[50%] -translate-x-[50%] top-[12%] items-end">
          <img src={img1} alt="img1" className="h-56" />
          <img src={img2} alt="img2" className="h-64  " />
          <img src={img3} alt="img3" className="h-56" />
        </div>
        <div className="absolute  left-1/2 top-[42%] -translate-x-[50%]">
          <h4 className="font-openSans text-4xl text-[#7a5868] font-bold text-center mb-8 uppercase">
            Join Mommy Squad!
          </h4>
          <p className="text-[#7a5868] font-openSans text-center w-[28rem] md:w-[33rem] font-medium mb-8 mx-auto">
            Don't miss out on the go-to place for pregnancy advice for hundreds
            of mommies around the world! Become more knowledgeable, confident,
            and calm about motherhood.
          </p>
          <button className="uppercase text-white bg-[#7a5868] py-2 px-8 rounded-full text-lg font-openSans md:w-[50%] mx-auto relative left-1/2 -translate-x-[50%] mb-8">
            Join Mommy Squad
          </button>
          <div className="">
            <img alt="love" src={loveHandImg} className="h-28 mx-auto mt-8" />
            <h6 className="text-[#7a5868] text-3xl md:text-4xl font-bold font-openSans uppercase mt-10 mb-10 text-center">
              24/7 Friendly Support
            </h6>
            <p className="font-openSans text-[#7a5868] font-semibold text-center relative -top-10 lg:-top-6 xl:-top-0">
              If you need any assistance with your doppler our friendly support
              team are here to help you 24/7 at{' '}
              <span className="text-white">support@cradlemebaby.com</span>
            </p>
          </div>
          <img
            src={deviceImg}
            alt="device"
            // max-w-[96.7vw] sm:max-w-[97.5vw] md:max-w-[99.6vw] lg:max-w-[99.7vw] xl:
            className="object-cover -mt-10 xl:-mt-4 -z-50 relative max-w-[100vw]  md:ml-[calc(49%-50vw)] md:w-screen md:mr-[calc(50%-50vw)]"
          />
        </div>

        <div className="bg-[#ceb3bc] w-full h-96 absolute bottom-0 -z-50"></div>
      </div>
    </section>
  )
}
export default JoinSection
