import bell from '../assets/p 2.png'
const DiscountBanner = () => {

  return (
    <div className="bg-[#fd4a82] flex items-center justify-center space-x-4 py-2">
      <img src={bell} alt="bell" className="h-4 w-4" />
      <p className="text-white uppercase font-light">
        Free Shipping on orders over $39
      </p>
      <img src={bell} alt="bell" className="h-4 w-4" />
    </div>
  )
}
export default DiscountBanner
