import Img from '../assets/k 3.jpg'
const HowToUseSection = () => {
  return (
    <section className="max-w-screen-xl mx-auto w-full py-8 space-y-10">
      <h3 className="font-semibold text-2xl text-center font-openSans mb-4 md:mb-0 ">
        HOW TO USE A FETAL DOPPLER
      </h3>
      <p className="font-openSans hyphens px-8 md:w-[66%] mx-auto font-medium">
        This handy device can be easily carried around, is simple, easy to use,
        and comes with simple instructions to help you learn how to use it in no
        time. Apply ultrasound gel onto the probe, find the comfortable spot on
        your belly and get comfortable & hear the sound of a heart that will
        fill yours!
      </p>
      <img src={Img} alt="routine" className="h-[40rem] mx-auto py-8" />
    </section>
  )
}
export default HowToUseSection
