import frameImg from '../assets/p 13.png'
import starsImg from '../assets/p 11.png'
import tubePackImg from '../assets/p 14.png'
import bellyBandImg from '../assets/p 15.png'
import bloodPressureImg from '../assets/p 16.png'

const data = [
  {
    id: 1,
    img: tubePackImg,
    title: 'Conductivity Gel - 3 Pack',
    ratings: '',
    price: '$24.95',
  },
  {
    id: 2,
    img: bellyBandImg,
    title: 'Belly Band',
    ratings: '',
    price: '$39.95',
    oldPrice: '$79.90',
  },
  {
    id: 3,
    img: bloodPressureImg,
    title: 'Blood Pressure Monitor',
    ratings: '',
    price: '$49.95',
    oldPrice: '$89.95',
  },
]
const ShopSection = () => {
  return (
    <section className="flex w-full py-8 my-4 max-w-screen-xl mx-auto justify-center gap-8 flex-wrap">
      {data.map(item => (
        <div key={item.id} className="">
          <div className="frame relative w-72">
            <img alt="product" src={item.img} />
          </div>
          <p className="font-semibold font-openSans mt-4">{item.title}</p>
          <img
            src={item.ratings || starsImg}
            alt="stars"
            className="h-4 my-1"
          />
          <p className="font-openSans font-medium text-lg">
            {item.price}{' '}
            {item.oldPrice && (
              <span className="line-through text-gray-400 ml-3">
                {item.oldPrice}
              </span>
            )}
          </p>
          <button className="uppercase border-2 border-[#ccc] py-2 px-8 w-[95%] mt-2">
            Shop Now
          </button>
        </div>
      ))}
    </section>
  )
}
export default ShopSection
